<template>
	<div
		class="input-wrapper"
		:class="[
			size,
			{ disabled },
			{ readOnly },
			{ dark },
			{
				error: $slots['error'],
				warning: $slots['warning'],
				info: $slots['info'],
				success: $slots['success'],
				focus: keyboardFocus,
			},
		]"
		@click="handleSetFocus"
		@keyup.tab="keyboardFocus = true"
		@focusout="keyboardFocus = false"
	>
		<div
			class="input-label"
			v-if="label || $slots['help-text']"
		>
			<TnLabel
				v-if="label"
				:for="uniqueId"
				:size="size"
				:dark="dark"
				:disabled="disabled"
				>{{ label }}</TnLabel
			>
			<div class="help-text">
				<!-- @slot Slot for displaying help text on the upper right on top of the input field -->
				<slot name="help-text">
					<span v-if="helpText">{{ helpText }}</span>
				</slot>
			</div>
		</div>
		<div
			class="input-field"
			:class="[{ loading }, { error: $slots['error'] }]"
		>
			<div
				v-if="iconLeft || $slots['icon-left']"
				class="icon-left"
			>
				<!-- @slot Slot for replacing icon on left-hand side with custom svg (overrides TnIcon) -->
				<slot name="icon-left">
					<TnIcon
						:name="iconLeft"
						:size="iconSize"
					/>
				</slot>
			</div>
			<input
				@input="updateValue"
				v-model="internalValue"
				:id="uniqueId"
				:disabled="disabled || readOnly"
				:name="name"
				ref="input-field"
				@keyup.esc="handleClear"
				v-bind="{ ...$attrs, class: '', style: '' }"
				@blur="$emit('blur')"
			/>
			<TnIcon
				v-if="isClearable"
				name="close"
				:size="iconSize"
				class="icon-clear"
				@click="handleClear"
				@keyup.enter="handleClear"
				tabindex="0"
			/>
			<TnIcon
				v-if="disabled"
				class="disabled-icon"
				name="lock-outline-closed"
				size="m"
			/>
			<div
				v-if="iconRight || $slots['icon-right']"
				class="icon-right"
			>
				<!-- @slot Slot for replacing icon on right-hand side with custom svg (overrides TnIcon) -->
				<slot name="icon-right">
					<TnIcon
						:name="iconRight"
						:size="iconSize"
					/>
				</slot>
			</div>
		</div>

		<div
			class="support-text"
			v-if="
				$slots['hint'] ||
				$slots['error'] ||
				$slots['warning'] ||
				$slots['warning'] ||
				$slots['info'] ||
				$slots['success']
			"
		>
			<TnSupportText
				v-if="$slots['hint']"
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying hint-text (no icon) via TnSupportText -->
				<slot name="hint"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['error']"
				critical
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying critical/error messages via TnSupportText -->
				<slot name="error"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['warning']"
				warning
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying warning messages via TnSupportText -->
				<slot name="warning"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['info']"
				info
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying informational messages via TnSupportText -->
				<slot name="info"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['success']"
				success
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying success messages via TnSupportText -->
				<slot name="success"></slot>
			</TnSupportText>
		</div>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * TnTextField is a component used for collecting information provided by the user.
 *
 * The component supports ***v-model*** and reuses the TnSupportText-component to display
 * feedback such as errors, warnings, success or general info via slots.
 * @displayName TnTextField
 */
export default defineComponent({
	name: "TnTextField",

	props: {
		/**
		 * Name of the input field for use with forms
		 */
		name: {
			type: String,
		},
		/**
		 * Label to associate and describe the input field
		 */
		label: {
			type: String,
		},
		/**
		 * Value of the input field, alternative to binding with v-model
		 */
		modelValue: {
			type: String,
		},
		/**
		 * Size of the input field
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "s",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the left side within the field
		 */
		iconLeft: {
			type: String,
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the right side within the field
		 */
		iconRight: {
			type: String,
		},
		/**
		 * Disabled the input
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Read only the input
		 */
		readOnly: {
			type: Boolean,
			default: false,
		},
		/**
		 * Show loading animation
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		/**
		 * Allows the input field by cleared by displaying a clickable clear icon, or pressing ESC on keyboard
		 */
		clearable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Help text that shows over the input on the right. Could be used to communicate that input is optional.
		 */
		helpText: {
			type: String,
			default: "",
		},
		dark: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			internalValue: this.modelValue,
			keyboardFocus: false,
		};
	},

	computed: {
		iconSize() {
			return this.size.toLowerCase() === "xs" ? "s" : "m";
		},
		isClearable() {
			return this.clearable && !this.disabled && this.internalValue?.length > 0;
		},
		uniqueId() {
			return this.$attrs.id || useId();
		},
	},

	watch: {
		modelValue: {
			immediate: true,
			handler(newValue) {
				this.internalValue = newValue;
			},
		},
		autofocus: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.handleSetFocus();
				}
			},
		},
	},

	methods: {
		updateValue(event) {
			this.$emit("update:modelValue", event.target.value);
		},
		handleClear(event) {
			if (this.isClearable) {
				event.stopPropagation();
				this.internalValue = "";
				this.$emit("cleared");
				this.$emit("update:modelValue", event.target.value || "");
			}
		},
		handleSetFocus() {
			this.$refs["input-field"]?.focus();
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/global-style/scss/mixins/loading" as loading;
@use "@/assets/scss/variables" as variables;
@use "sass:color";

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.input-wrapper {
	--field-background-color: #{variables.$color-neutrals-white};
	--field-background-color-disabled: #{variables.$color-neutrals-50-tint};
	--field-background-color-read-only: #{variables.$color-neutrals-50-tint};
	--field-border-color: #{variables.$color-neutrals-400-tint};
	--field-hover-color: #{variables.$color-cta-hover};
	--field-focus-color: #{variables.$color-cta-focus};
	--field-active-color: #{variables.$color-cta-active};
	--field-error-color: #{variables.$color-critical-500-core};
	--field-icon-color: #{variables.$color-neutrals-500-core};
	--field-text-color: #{variables.$color-primary-dark};
	--field-text-color-disabled: #{variables.$color-neutrals-400-tint};
	--field-text-color-read-only: #{variables.$color-primary-dark};
	--field-clear-icon-color: #{variables.$color-neutrals-black};
	--field-help-text-color: #{variables.$color-neutrals-500-core};

	cursor: text;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&.dark {
		--field-background-color: rgba(#{hexToRGB(variables.$color-neutrals-800-shade)});
		--field-background-color-disabled: rgba(#{hexToRGB(variables.$color-neutrals-900-shade)});
		--field-background-color-read-only: rgba(#{hexToRGB(variables.$color-neutrals-900-shade)});
		--field-border-color: #{variables.$color-neutrals-800-shade};
		--field-hover-color: #{variables.$color-cta-dark-hover};
		--field-focus-color: #{variables.$color-cta-dark-focus};
		--field-active-color: #{variables.$color-cta-dark-active};
		--field-error-color: #{variables.$color-critical-500-core};
		--field-icon-color: #{variables.$color-cta-dark-default};
		--field-disabled-icon-color: #{variables.$color-cta-dark-active};
		--field-text-color: #{variables.$color-cta-dark-default};
		--field-text-color-disabled: #{variables.$color-neutrals-800-shade};
		--field-text-color-read-only: #{variables.$color-neutrals-white};
		--field-clear-icon-color: #{variables.$color-neutrals-white};
		--field-help-text-color: #{variables.$color-neutrals-200-tint};
	}

	input {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.s {
		gap: 6px;

		.input-field {
			padding: 0 16px;
			height: 40px;

			input {
				height: 18px;

				@extend %font-text-s;
			}
		}

		.help-text {
			@extend %font-text-2xs;
		}
	}

	&.m {
		.input-field {
			padding: 0 16px;
			height: 48px;

			input {
				height: 24px;

				@extend %font-text-m;
			}
		}

		.help-text {
			@extend %font-text-xs;
		}
	}

	&.l {
		.input-field {
			padding: 0 16px;
			height: 56px;

			input {
				@extend %font-text-l;
			}
		}

		.help-text {
			@extend %font-text-s;
		}
	}

	.input-field {
		background-color: var(--field-background-color);
		box-shadow: 0 0 0 1px var(--field-border-color);
		border-radius: 8px;
		box-sizing: border-box;
		display: inline-flex;
		align-items: center;
		width: 100%;
		height: 100%;

		@include loading.loading;

		&:hover {
			box-shadow: 0 0 0 2px var(--field-hover-color);
		}

		&:focus-within {
			box-shadow: 0 0 0 2px var(--field-focus-color);
		}

		&.error {
			box-shadow: 0 0 0 2px var(--field-error-color);
		}

		input {
			width: 100%;
			border: none;
			outline: none;
			background: none;
			font-family: variables.$font-family-telenor-base;
			color: var(--field-text-color);

			&::placeholder {
				color: var(--field-help-text-color);
			}
		}

		.icon-left,
		.icon-right,
		.icon-clear {
			cursor: default;
			color: var(--field-icon-color);
			display: flex;
			align-items: center;
			font-family: variables.$font-family-telenor-ui;
		}

		.icon-left {
			margin-right: 8px;
		}

		.icon-right {
			margin-left: 8px;
		}

		.icon-clear {
			cursor: pointer;
			color: var(--field-clear-icon-color);
		}

		.disabled-icon {
			color: var(--field-disabled-icon-color);
		}
	}

	.input-label {
		display: flex;
		justify-content: space-between;
	}

	.help-text {
		color: var(--field-help-text-color);
	}

	.support-text {
		display: flex;
		justify-content: flex-start;
	}

	&.disabled {
		.input-field {
			background-color: var(--field-background-color-disabled);

			input {
				color: var(--field-text-color-disabled);
			}
		}

		* {
			cursor: not-allowed;
		}
	}

	&.readOnly {
		label {
			color: variables.$color-neutrals-600-shade;
		}

		.input-field {
			--field-background-color: var(--field-background-color-read-only);
			--field-border-color: var(--field-background-color-read-only);

			user-select: all;

			input {
				color: var(--field-text-color-read-only);
			}
		}
	}
}
</style>
